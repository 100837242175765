const state = {
    sideNavOpen: true,
    activeIndex: 0,
    verifyEmailData: {},
    authToken: null,
    accountNumbers: [],
    isFetchingAccounts: false,
    isFetchingBalances: false,
    isAuthenticated: false,
    expiry_in: null,
    balance: {},
    authenticatedUser: null,
    isFetchingTransactionFee: false,
    transactionFee: 0,
    selectedWallet:{},
    wallets: [],
};
export default state;