import {createStore} from "vuex";
import createPersistedState from 'vuex-persistedstate';
import Cookies from 'js-cookie';
import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
import state from "./state";

export default createStore({
    state,
    mutations,
    getters,
    actions,
    plugins: [createPersistedState({
        storage: {
            getItem: key => Cookies.get(key),
            setItem: (key, value) => Cookies.set(key, value, {
                expires: new Date(new Date().getTime() + 60 * 60 * 1000),
                secure: true,
            }),
            removeItem: key => Cookies.remove(key)
        }
    })],
});