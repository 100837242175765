import main from './layout/index'

const routes = [
    {
        path: "/layout",
        name: "Layout",
        component: () => import("../../components/reseller/layout/Layout"),
        meta: {
            title: "Tanda | Home",
            requiresAuth: true
        },
        children: main
    },
]

export default routes;
