import {createApp} from "vue";
import App from "./App.vue";
import "./assets/styles/app.sass";
import "./index.css";
import 'v-calendar/dist/style.css';
import { DatePicker } from 'v-calendar';

import $notification from '@/utils/NotificationToast'
import $bus from './utils/EventBus';
import router from "./router";
import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';

import './assets/tailwind.css'
import store from "/src/store";
import AOS from 'aos'
import 'aos/dist/aos.css'

const app = createApp(App);
app.config.globalProperties.$notification = $notification;
app.component('datepicker', DatePicker)
app.use(Donut);
app.use(AOS.init());
app.config.globalProperties.$bus = $bus;
app.use(store);
app.use(router);
app.mount("#app");
