import {createRouter, createWebHistory} from "vue-router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import auth from "./auth/index";
import reseller from "./reseller/index"
import store from "../store/index";

const app = [{
    path: "/", component: () => import("../components/main/index"), meta: {
        title: "Tanda | Reseller Portal", guest: true
    },
}, {
    path: "/404", component: () => import("../components/main/notFound"), meta: {
        title: "Tanda | 404 Page Not Found", guest: true
    },
}, {path: '/:catchAll(.*)', redirect: '/404'},];
let indexRoutes = app.concat(auth);
let routes = indexRoutes.concat(reseller);

const router = createRouter({
    history: createWebHistory("/"), routes
});

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    let tokenExpiryMs = (store.state.expiry_in !== null) ? parseInt(store.state.expiry_in) : null;
    const loggedIn = store.state.isAuthenticated;
    const isAuth = to.matched.some((record) => record.meta.requiresAuth);
    const isGuest = to.matched.some((record) => record.meta.guest);
    if (isAuth) {
        if (loggedIn) {
            if (tokenExpiryMs !== null) {
                if (Date.now() >= (tokenExpiryMs * 1000)) {
                    router.push('/login')
                        .catch(() => {
                        })
                } else {
                    next();
                }
            } else {
                router.push('/login')
                    .catch(() => {
                    })
            }
        } else {
            router.push('/login')
                .catch(() => {
                })
        }
    } else if (isGuest) {
        next();
    }

});

router.beforeResolve((to, from, next) => {
    if (to.name) {
        NProgress.start();
    }
    next();
});

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
    NProgress.done();
});

export default router;
