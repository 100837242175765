const routes = [
    {
        path: "/dashboard",
        name: "Dashboard",
        component: () => import("@/components/reseller/dashboard"),
        meta: {
            title: "Tanda | Dashboard",
            requiresAuth: true
        },
    },
];

export default routes;