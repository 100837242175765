let getters = {
    expiry_in: (state) => {
        return state.expiry_in;
    },
    activeIndex: (state) => {
        return state.activeIndex
    },
    sideNavOpen: (state) => {
        return state.sideNavOpen;
    },
    accountNumbers: (state) => {
        return state.accountNumbers;
    },
    isAuthenticated: (state) => {
        return state.isAuthenticated;
    },
    authenticatedUser: (state) => {
        return state.authenticatedUser;
    },
    isFetchingAccounts: (state) => {
        return state.isFetchingAccounts;
    },
    isFetchingBalances: (state) => {
        return state.isFetchingBalances;
    },
    balance: (state) => {
        return state.balance;
    },
    transactionFee: (state) => {
        return state.transactionFee;
    },
    isFetchingTransactionFee: (state) => {
        return state.isFetchingTransactionFee;
    },
    selectedWallet: (state) => {
        return state.selectedWallet;
    },
    wallets: (state) => {
        return state.wallets;
    },

};
export default getters;