<template>
  <div v-if="show" class="cursor-pointer">
    <div class="w-full fixed h-full top-0 left-0 right-0 bottom-0"
         style="z-index: 3; background-color: rgba(0,0,0,0.3)">
    </div>
    <div class="absolute w-full center-contents top-0 left-0 right-0 bottom-0 h-full">
      <div style="z-index: 4;" class="h-48 w-96 center-contents p-3 bg-white rounded">
        <div class="text-center">
          <p class="font-bold text-tandaRed text-xl">Authentication required</p>
          <p class="font-light text-sm">You are required to log in</p>
          <button
              @click="login"
              class="mt-5 border hover:bg-transparent hover:text-tandaPurple hover:border-tandaPurple
             text-sm text-white bg-tandaPurple action-buttons">
            Log in
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthRequiredModal",
  props: {
    show: {
      required: true,
      type: Boolean,
      default: false
    },
  },
  methods: {
    login() {
      this.$bus.trigger('auth-modal-closed')
    }
  }
}
</script>

<style scoped>

</style>