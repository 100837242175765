import dashboard from '../dashboard';
import transactions from '../transactions'
import wallets from '../wallets'

const childrenRoutes = [
    {
        path: "/manage-profile",
        name: "My Profile",
        component: () => import("@/components/reseller/profile/MyProfile"),
        meta: {
            title: "Tanda | Manage Profile",
            requiresAuth: true
        },
        children: [
            {
                path: "/edit-profile",
                name: "EditProfile",
                component: () => import("@/components/reseller/profile/ManageProfile"),
                meta: {
                    title: "Tanda | Edit profile",
                    requiresAuth: true
                },
            },
            {
                path: "/account-numbers",
                name: "Accounts",
                component: () => import("@/components/reseller/profile/AccountNumbers"),
                meta: {
                    title: "Tanda | Account numbers",
                    requiresAuth: true
                },
            },
            {
                path: "/switch-organisation",
                name: "SwitchOrgs",
                component: () => import("@/components/reseller/profile/SwitchOrganization"),
                meta: {
                    title: "Tanda | Switch Accounts",
                    requiresAuth: true
                },
            }
        ]
    },
]
const indexRoutes = [];
const routes = indexRoutes.concat(childrenRoutes, dashboard, transactions, wallets);
export default routes

