import AuthAPI from "../api/authAPI";

export default {
  fetchAccountNumbers({ commit }, organizationId) {
    let url = `/wallets/v1/organizations/${organizationId}/account-numbers`;
    commit("IS_FETCHING", true);
    AuthAPI.get(url)
      .then((response) => {
        if (response.status === 200) {
          commit("FETCH_ACCOUNT_NUMBERS", response.data.content);
          commit("IS_FETCHING", false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  fetchBalances({ commit }, organizationId) {
    let url = `/wallets/v1/orgs/${organizationId}/balances?accountTypes=01,02`;
    commit("IS_FETCHING_BAL", true);
    AuthAPI.get(url)
      .then((response) => {
        if (response.status === 200) {
          commit("FETCH_BALANCES", response.data);
          commit("IS_FETCHING_BAL", false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  fetchTransactionFee({ commit }, data) {
    const params = new URLSearchParams([
      ["spId", data.params.spId],
      ["cmdId", data.params.cmdId],
      ["amnt", data.params.amount],
    ]);
    const config = {
      params: params,
    };
    let url = `/wallets/v1/organizations/${data.organizationId}/quotes`;
    commit("IS_FETCHING_TRANSACTION_FEE", true);
    AuthAPI.get(url, config)
      .then((response) => {
        if (response.status === 200) {
          commit(
            "FETCH_TRANSACTION_FEE",
            response.data.organization.commissionFee
          );
          commit("IS_FETCHING_TRANSACTION_FEE", false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  setSideNavOpen({ commit }, data) {
    commit("SET_SIDENAV_OPEN", data);
  },
  setWallets({ commit }, data) {
    commit("SET_WALLETS", data);
  },
};
