const routes = [
    {
        path: "/login",
        name: "Login",
        component: () => import("../../components/auth/login/Login.vue"),
        meta: {
            title: "Tanda | Login",
            guest: true
        }
    },
    {
        path: "/password-reset",
        name: "Password Reset",
        component: () => import("../../components/auth/login/PasswordReset"),
        meta: {
            title: "Tanda | Password Reset",
            guest: true
        }
    },
    {
        path: "/change-password",
        name: "Change Password",
        component: () => import("../../components/auth/login/VerifyOTP"),
        meta: {
            title: "Tanda | Verify OTP",
            guest: true
        }
    },
]

export default routes;
