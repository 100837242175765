<template>
  <div id="app">
    <SessionExpiry :show="show"/>
    <AuthRequiredModal :show="showAuth"/>
    <router-view></router-view>
  </div>
</template>
<script>
import SessionExpiry from "./components/appReusableComponents/modal/SessionExpiry";
import AuthRequiredModal from "./components/appReusableComponents/modal/AuthRequiredModal";

export default {
  components: {AuthRequiredModal, SessionExpiry},
  data() {
    return {
      show: false,
      showAuth: false
    }
  },
  created() {
    this.$bus.on('session-expired', () => this.showExpiryModal())
    this.$bus.on('session-modal-closed', () => this.closeExpiryModal())
    this.$bus.on('auth-modal-open', () => this.showAuthModal())
    this.$bus.on('auth-modal-closed', () => this.closeAuthModal())
  },
  methods: {
    showExpiryModal() {
      this.show = true
      this.$bus.off('session-expired')
      clearTimeout()
    },
    closeExpiryModal() {
      this.show = false
      this.$bus.off('session-modal-closed')
      clearTimeout()
      this.$router.push({name: 'Login'})
    },
    showAuthModal() {
      this.showAuth = true
      this.$bus.off('auth-modal-open')
      clearTimeout()
    },
    closeAuthModal() {
      this.showAuth = false
      this.$bus.off('auth-modal-closed')
      clearTimeout()
      this.$router.push({name: 'Login'})
    }
  }
}
</script>
<style lang="sass">
#app
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  color: #2c3e50

#nprogress
  .bar
    background: #00B1A8 !important
    height: 6px

  .spinner-icon
    border: none !important
</style>
