const routes = [
    {
        path: "/collections/wallet",
        name: "Collections",
        component: () => import("@/components/reseller/wallets/View"),
        meta: {
            title: "Tanda | Collections",
            requiresAuth: true
        },
    },
    {
        path: "/wallet/initialize-collection",
        name: "Wallets",
        component: () => import("@/components/reseller/wallets/initialize"),
        meta: {
            title: "Tanda | Initialize wallet",
            requiresAuth: true
        },
    },
    {
        path: "/wallets/view",
        name: "View Wallet",
        component: () => import("@/components/reseller/wallets/walletActions/ViewWallet"),
        meta: {
            title: "Tanda | Balances",
            requiresAuth: true
        },
    },
    {
        path: "wallet",
        name: "Wallet Balance",
        component: () => import("@/components/reseller/wallets/index"),
        meta: {
            title: "Tanda | Wallets",
            requiresAuth: true
        },

        children: [
            {
                path: "/wallet/transaction-history",
                name: "Transaction History",
                component: () => import("@/components/reseller/wallets/walletActions/TransactionHistory"),
                meta: {
                    title: "Tanda | Transaction History",
                    requiresAuth: true
                },
            },
            {
                path: "/wallet/withdraw",
                name: "Withdraw",
                component: () => import("@/components/reseller/wallets/walletActions/Withdraw"),
                meta: {
                    title: "Tanda | Withdraw",
                    requiresAuth: true
                },
            },
            // {
            //     path: "/wallet/balances",
            //     name: "General",
            //     component: () => import("@/components/reseller/wallets/walletActions/General"),
            //     meta: {
            //         title: "Tanda | Balances",
            //         requiresAuth: true
            //     },
            // },
            {
                path: "/wallets",
                name: "Wallets",
                component: () => import("@/components/reseller/wallets/walletActions/Wallets"),
                meta: {
                    title: "Tanda | Balances",
                    requiresAuth: true
                },
            },
        ]
    }


];

export default routes;