let mutations = {
    SET_ACTIVE_INDEX(state, data){
        return (state.activeIndex = data)
    },
    SET_SIDENAV_OPEN(state, data){
        return (state.sideNavOpen = data)
    },
    SET_AUTH_TOKEN(state, data) {
        return (state.authToken = data);
    },
    SET_AUTHENTICATED_USER(state, data) {
        return (state.authenticatedUser = data);
    },
    SET_SELECTED_WALLET(state, data){
        return (state.selectedWallet = data);
    },
    SET_WALLETS(state, data){
        return (state.wallets = data);
    },
    FETCH_ACCOUNT_NUMBERS(state, data) {
        return (state.accountNumbers = data);
    },
    IS_FETCHING(state, data) {
        return (state.isFetchingAccounts = data);
    },
    FETCH_BALANCES(state, data) {
        return (state.balance = data);
    },
    IS_FETCHING_BAL(state, data) {
        return (state.isFetchingBalances = data);
    },
    FETCH_TRANSACTION_FEE(state, data) {
        return (state.transactionFee = data);
    },
    IS_FETCHING_TRANSACTION_FEE(state, data) {
        return (state.isFetchingBalances = data);
    },
    SET_AUTHENTICATION(state, status) {
        state.isAuthenticated = status;
    },
    TANDA_TOKEN_EXPIRY(state, status) {
        state.expiry_in = status;
    },
};
export default mutations;