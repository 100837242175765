import axios from "axios";
// import https from "https";
import store from '../store/index'
import router from "@/router";

const AuthAPI = axios.create({
    // httpsAgent: new https.Agent({
    //     rejectUnauthorized: false
    // }),
    baseURL: process.env.VUE_APP_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});
const responseSuccessHandler = response => {
    return response;
};

const responseErrorHandler = error => {
    if (error.response.status === 401) {
        router.push('/login')
            .catch(() => {
            })
    }
    return Promise.reject(error);
}
AuthAPI.interceptors.response.use(
    response => responseSuccessHandler(response),
    error => responseErrorHandler(error)
);
AuthAPI.interceptors.request.use(function (config) {
    const token = store.state.authToken
    config.headers.Authorization = token ? `Bearer ${token.access_token}` : '';
    return config;
});

export default AuthAPI