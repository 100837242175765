const routes = [
    {
        path: "/transaction-history",
        name: "Transaction",
        component: () => import("@/components/reseller/transactions/Blade"),
        meta: {
            title: "Tanda | Transactions",
            requiresAuth: true
        },
        children: [
            {
                path: "/transactions",
                name: "Transactions",
                component: () => import("@/components/reseller/transactions/index"),
                meta: {
                    title: "Tanda | Transactions",
                    requiresAuth: true
                },
            }]
    }
];

export default routes;